<template>
	<div>
		<s-crud
			title="Cronograma Embarque"
			:filter="filter"
			:config="configBoardingSchedule"
			@rowSelected="rowSelected($event)"
			add
			edit
			@EditEvent="EditEvent()"
			remove
			@save="save($event)"
		>
			<!-- FILTRAR POR FECHAS DE EMBARQUE Y FECHA DE CARGA  -->
			<template v-slot:filter>
			<v-col>
				<v-row justify="center">
					<v-col cols="12" lg="3" sm="3" md="3">
						<s-date v-model="filter.BosDate" label="Fecha Embarque"></s-date>
					</v-col>
					<v-col cols="12" lg="3" sm="3" md="3">
						<s-date v-model="filter.BosUploadDate" label="Fecha Carga"></s-date>
					</v-col>				
				</v-row>
			</v-col>
			</template>
			<!-- FIN DEL FILTRO  -->
			<template slot-scope="props">
				<v-col>
					<v-row>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="3">
							<s-search-by-o-v @enterpress = "enterpress($event, props.item)" ></s-search-by-o-v>
						</v-col>						
					</v-row>
					<v-row>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="3">
							<s-date v-model="props.item.BosDate" label="Fecha Embarque"></s-date>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="3">
							<s-text number v-model="props.item.BosTemperature" label="Temperatura"></s-text>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="3"  sm="3" md="3" >
							<s-select-type-certification v-model="props.item.TcID" full autofocus label="Tipo Certificación"></s-select-type-certification>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="3">
							<s-date v-model="props.item.BosUploadDate" label="Fecha Carga"></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="3">
							<s-text v-model="props.item.VrtName" disabled label="Variedad"></s-text>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="3">
							<s-date v-model="props.item.BosOVDate" label="Fecha de Emisión" disabled></s-date>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="6" sm="6" md="9">
							<s-text v-model="props.item.CliCode" label="Cliente" disabled></s-text>	
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="s-col-form" lg="3" md="3" sm="3">
							<s-select-definition v-model="props.item.TypeTransport" :def="1280" label="Transporte/Embarque" disabled></s-select-definition>
							<!-- <s-text v-model="props.item.TypeTransport" label="Tipo de Transporte/Embarque"></s-text> -->
						</v-col>
						<v-col cols="12" class="s-col-form" lg="3" sm="3" md="2">
							<s-select-definition v-model="props.item.TypeProcess" :def="1248" label="Tipo Proceso" disabled></s-select-definition>
						</v-col>
					</v-row>					
					<br><br>
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="12" sm="12" md="12">
							<s-toolbar		
								color="teal darken-1"
								class="white--text text--lighten-1"
								:elevation="2"								
								label="Detalle Orden de Venta"
							></s-toolbar>
						</v-col>
					</v-row>
					<v-container>
						<v-col>							
							<v-row justify="center">								
								<v-col cols="12" class="s-col-form" lg="12" sm="12" md="12"><br>
									<v-divider oscuro></v-divider>
									<v-divider oscuro></v-divider>
									<v-divider oscuro></v-divider>
								</v-col>
							</v-row>
							<v-row justify="center">							
								<v-col cols="12" class="s-col-form" lg="12" sm="12" md="12">									
									<v-data-table										 
										dense										
										v-model="selectedMaterial"
										:headers="headers1"
										:items="SlsBoarningScheduleDetail"
										item-key="ArcID"								
										class="elevation-0"
									></v-data-table>								
								</v-col>                       
							</v-row>
						</v-col>												
					</v-container>
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="12" sm="12" md="12">
							<s-toolbar 
								color="teal lighten-4"
								class="white--text text--lighten-1"
								:elevation="2"							
							></s-toolbar>
						</v-col>
					</v-row>										
				</v-col>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sBoardingScheduleService from "@/services/FreshProduction/BoardingScheduleService"
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SSelectTypeCertification from '@/components/Utils/Certification/sSelectTypeCertification.vue';
	import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';
	import SText from '../../../components/Utils/SText.vue';
	import SSearchByOV from '../../../components/FreshProduction/BoardingSchedule/SSearchByOV.vue';

	export default {
		components: { SSelectVariety, SSelectTypeCertification, SSelectDefinition, SText, SSearchByOV },
		data() {
			return {				 
				VrtName: "",
				TypeTransport:"",
				TypeTransportText:"0",
				BosPalletBoxs:"",
				Presentation:"",
				Caliber:"0",
				Quantity: 0.0,
				BosKilosPresentation:"",
				ArtCode:"",
				TypeProcess:"",
				TypeProcessText:"",	
				VrtID: "",
				VrtDescription: "",
				Presentation:"",
				BosOVDate:"",
				TypeCrop: null,
				TypeCropText: "",	
				TypeCultive: null,
				TypeCultiveText:"",
				Quantity: "",
				OrderSale:"",
				CliCode: "",	
				objArticle: {},
				BosOV: "",				 
				headers1: [],
				filter: {},
				selectedMaterial: [],
				SlsBoarningScheduleDetail: [],
				configBoardingSchedule: {
					model: {
						BosID: "ID",
						VrtID: "int",
						BosOV: "int",
						BosDate: "date",
						BosUploadDate: "date",
						Priority: "",						
					},
					service: _sBoardingScheduleService,
					headers: [
						{ text : "ID", 					value : "BosID"				},
						{ text : "Tipo de Transporte", 	value : "TypeTransportText"	},
						{ text : "Tipo Proceso", 		value : "TypeProcessText"	},
						{ text : "Tipo Certificación", 	value : "TcDescription"		},
						{ text : "Fecha Embarque", 		value : "BosDate"			},
						{ text : "Fecha Carga", 		value : "BosUploadDate"		},
					],
				},
				selectedMaterial: [],
						headers1: [
							{ text : "ID", 				value : "ArtCode" 	  		   , width:90  },
							{ text : "Descripción", 	value : "ArtDescription"	   , width:350 },
							{ text : "Presentación", 	value : "Presentation"	 	   , width:200 },
							{ text : "Calibre", 		value : "Caliber" 			  },
							{ text : "Cajas/Pallet", 	value : "BosPalletBoxs"		  },
							{ text : "Kilos",	 		value : "Quantity" 			  },
							{ text : "Kg/Prest.",		value : "BosKilosPresentation"},
							{ text : "Tipo Cultivo", 	value : "TypeCropText"		  },
							{ text : "Cultivo", 		value : "TypeCultiveText"	  },
							
						],
						itemsMaterial: [],
			}
		},
		methods: {
			rowSelected(item) {
				this.SlsBoarningScheduleDetail 	= [];
					console.log("BOSOV", item[0].BosOV);
					console.log('cabecera', item[0].BosID);
					_sBoardingScheduleService
					.listBosOV( this.$fun.getUserID(), item[0].BosID )
					.then((resp) => {
						if(resp.status == 200){
					 let data = resp.data;
					 this.SlsBoarningScheduleDetail = data;	
					 console.log("dd",data)						
						}
					})
			},
			EditEvent(){
				this.SlsBoarningScheduleDetail 	= item.SlsBoarningScheduleDetail;
				if (item.VrtID == 0) {
					
					item.VrtID= this.VrtID;
					return;
				}
			},			
			enterpress(item, sap){
				console.log('imprime ',item);
				sap.CliCode 			 = item.CliCode;  
				sap.BosPalletBoxs 		 = item.BosPalletBoxs;  
				sap.Caliber 			 = item.Caliber;  
				sap.Quantity 			 = item.Quantity;  
				sap.Presentation 		 = item.Presentation;
				sap.BosKilosPresentation = item.BosKilosPresentation;
				sap.ArtCode 			 = item.ArtCode;
				sap.BosOVDate 			 = item.BosOVDate;
				sap.TypeTransport 		 = item.TypeTransport;
				sap.TypeTransportText 	 = item.TypeTransportText;
				sap.TypeProcess 		 = item.TypeProcess;
				sap.TypeProcessText 	 = item.TypeProcessText;
				sap.VrtID				 = item.VrtID;
				this.VrtID				 = item.VrtID;
				sap.VrtName				 = item.VrtName;
				sap.VrtDescription 		 = item.VrtDescription;				
				sap.TypeCrop 			 = item.TypeCrop;
				sap.TypeCropText 		 = item.TypeCropText;
				sap.TypeCultive 		 = item.TypeCultive;
				sap.TypeCultiveText 	 = item.TypeCultiveText;
				sap.BosOV				 = item.BosOV;
				console.log("sss", sap.BosOV)
				//this.SaleOrder 			 = item.BosOV;
				this.SlsBoarningScheduleDetail 		 = item.SlsBoarningScheduleDetail;
				sap.SlsBoarningScheduleDetail		 = item.SlsBoarningScheduleDetail;	
			},

			save(item){
				item.VrtID = this.VrtID;
				
				
				if(item.BosTemperature ==''){
					this.$fun.alert("Ingrese la temperatura", "warning");
					return;
				}
				console.log('item ', item);
				item.save()
				.then((resp) => {
					this.SlsBoarningScheduleDetail = [];
				});
			},	
		},
		watch:{
		},
		created () {
			this.filter = {};
		},
	}
</script>