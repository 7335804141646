import Service from '../Service';

const resource = "boardingschedule/";

export default {

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    listSaleOrder(SaleOrder, requestID) {
        console.log("service")
        return Service.post(resource + "listSaleOrder", SaleOrder.SaleOrder, {
            params: { requestID: requestID, SaleOrder },
        });
    },

    listBosOV(RequestID, BosID){    
        return Service.post(resource + "listBosOV",{},{
        params:{RequestID: RequestID, BosID: BosID}
      });
    },

}