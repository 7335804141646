<template>
    <div>
        <s-text            
            autofocus
            :label="label"
            search-icon
            @clearable ="clearable($event)"                  
            @keyupEnter="keyupEnter($event)"
            v-model="BosOV"
        >            
        </s-text>        
    </div>
</template>
<script>
import _sBoardingScheduleService from "@/services/FreshProduction/BoardingScheduleService";
export default {
    name: "SSearchByOV",
    props: {        
        label: { type: String, default: "BosOV / Num. Orden de Venta" },
        },
        data( ){
            return{
                SaleOrder : "",
                BosOV: "",
                headers: [],
                config:{
                    model:{
                        BosID: "ID",
                        BosOV: "int",
                    },
                },                
            }
        },
        methods: {
            
            input(item){
                
            },
            clearable(){
                this.$emit("clearup", data);
            },
            keyupEnter()
			{
                console.log(this.BosOV)	
                _sBoardingScheduleService
                .listSaleOrder(this.BosOV, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        let data = resp.data;
                        console.log(data)
                        this.$emit("enterpress", data);
                    }
                })
			},

             
        },    
}
</script>

